import { FormikHelpers, FormikValues, getIn, setNestedObjectValues, useFormikContext } from 'formik'
import { find, isArray, isEqual, merge } from 'lodash'
import groupBy from 'lodash/groupBy'
import keyBy from 'lodash/keyBy'
import omitBy from 'lodash/omitBy'
import partition from 'lodash/partition'
import pickBy from 'lodash/pickBy'
import sortBy from 'lodash/sortBy'
import sumBy from 'lodash/sumBy'
import uniq from 'lodash/uniq'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UseQueryResult } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  Classifiers,
  CurrentFacility,
  CurrentUser,
  ErrorResponse,
  FacilityType,
  InventoryModification,
  ModificationPartitionAggregate,
  RoleId,
  useClassifiers,
  useFacility,
  useFacilityPartnerOptions,
  useFactoryProfileBrands,
  useFactoryProfileHandlers,
  useFactoryProfileUpdate,
  useIncomingShipmentPartitionModifications,
  useIncomingShipmentVolumes,
  useRecyclerProfilePartners,
  useRemainingPartitionsForFacility,
} from 'src/api'
import { useFactoryProfileUpdate as useAdminFactoryProfileUpdate } from 'src/api/admin'
import useActiveRole from 'src/state/role'
import { formatVolumeNumber } from 'src/utils'
import { convertEmptyStringsToNulls } from 'src/utils/Normik'

import { BrandInfo } from './brands'
import { useClassifiersTranslation } from './useClassifiersTranslation'

export { useDebounce } from './useDebounce'
export { useDownloadFile } from './useDownloadFile'

export const COMPOSITION_GROUP_FILTER = {
  filterAttribute: 'name',
  redList: [
    'Ceramic',
    'Stone crushes',
    'Glass',
    'Metal',
    'Aluminium',
    'Steel',
    'Zinc',
    'Brass',
    'Iron',
    'Wood',
    'Cork',
    'Mixed / unidentified material',
  ],
}

export const useAllProductionMaterialTypes = () => {
  const { data: classifiers }: { data: any } = useClassifiers()
  return classifiers?.productionMaterialTypes
}

export const useProductionMaterialTypes = (productionTypeIds: number[]) => {
  const { data: classifiers }: { data: any } = useClassifiers()
  const productionMaterialTypes = useClassifierActiveOptionList('productionMaterialTypes', 'id', 'name')

  return useMemo(() => {
    const materialTypeIds = classifiers?.productionTypes
      // allowing all categories if no types selected in profile
      .filter((t) => t.isDefault || productionTypeIds?.includes(t.id) || !productionTypeIds?.length)
      .map((t) => t.productionMaterialTypeIds)
      .flat()

    return productionMaterialTypes?.filter((t) => materialTypeIds.includes(Number(t.id)))
  }, [classifiers?.productionTypes, productionMaterialTypes, productionTypeIds])
}

export const useProductionCompositions = (isTextileMaterial) => {
  const { data: compositions } = useClassifierOptions('productionCompositions')
  const filteredCompositions = compositions?.filter(
    (c) =>
      !c.isDeprecated &&
      (isTextileMaterial === null || c.isTextile === isTextileMaterial || !c.isTextile === !isTextileMaterial),
  )
  return filteredCompositions
}

export const useGroupedProductionMaterialTypeOptions = (productionTypeIds: number[]) => {
  const { t } = useTranslation()
  const { data: productionTypesData } = useClassifierOptions('productionTypes')
  const { data: productionMaterialTypes } = useClassifierOptions('productionMaterialTypes')

  return useMemo(() => {
    // allowing all categories if no types selected in profile
    const productionTypes = productionTypesData?.filter(
      (t) => t.isDefault || productionTypeIds?.includes(Number(t.id)) || !productionTypeIds?.length,
    )

    // hack: using isDefault as grouping key because it happens to correspond to non-textile production types we care about
    return partition(productionTypes, ['isDefault', false]).map((productionTypes, index) => {
      const productionTypeIds = productionTypes.flatMap((t) => t.productionMaterialTypeIds)
      return {
        label: index === 0 ? '' : t('Other'),
        options: productionMaterialTypes
          ?.filter((t) => productionTypeIds.includes(Number(t.id)))
          .map((t) => ({ ...t, value: t.id, label: t.name })),
      }
    })
  }, [productionTypesData, productionMaterialTypes, productionTypeIds, t])
}

export const useProductionMaterialType = (productionMaterialtypeId: number) => {
  const { data: classifiers }: { data: any } = useClassifiers()
  return useMemo(
    () => classifiers?.productionMaterialTypes.find((t) => t.id === productionMaterialtypeId),
    [classifiers?.productionMaterialTypes, productionMaterialtypeId],
  )
}

export const useProfileProductionMaterialTypes = (productionTypes: number[]) => {
  const getProfileProductionMaterialTypes = useGetClassifierSubOptions(
    'productionTypes',
    'id',
    'name',
    'profileProductionMaterialTypes',
    'id',
    'name',
  )
  return useMemo(
    () => productionTypes?.flatMap((t) => getProfileProductionMaterialTypes(t.toString()) ?? []) ?? [],
    [productionTypes, getProfileProductionMaterialTypes],
  )
}

export const useProductionMaterialWeightTypes = () => {
  const classifiers = useClassifierOptionList('profileProductionMaterialWeightTypes')
  return useMemo(() => classifiers.filter((item) => item.unitWeightKg), [classifiers])
}

export const useGetProfileCompositionRows = () => {
  const getCompositionName = useGetClassifier('profileProductionCompositions', 'id', 'name')
  return useCallback(
    (value) =>
      value?.map(
        (v) =>
          v.description ?? getCompositionName(v.profileProductionCompositionId) ?? v.profileProductionCompositionId,
      ) ?? null,
    [getCompositionName],
  )
}

const PRODUCTION_TYPES_FOR_TEXTILE_QUESTIONS = [
  'Fabric Mill',
  'Yarn production / spinning mill',
  'Garment / apparel manufacturing (CMT/RMG)',
]

export function useTextileProductionTypeIds() {
  return useClassifierIdsFromNames(PRODUCTION_TYPES_FOR_TEXTILE_QUESTIONS, 'productionTypes')
}

const PRODUCTION_TYPES_FOR_FABRIC_TEXTURE_QUESTIONS = [
  'Wet processes (dyeing/washing)',
  'Printing, embroidery',
  'Textile Linens and homewear',
]

export function useFabricTextureProductionTypeIds() {
  return useClassifierIdsFromNames(PRODUCTION_TYPES_FOR_FABRIC_TEXTURE_QUESTIONS, 'productionTypes')
}

const ACTIVITY_WASTE_VOLUME_DETAILS = {
  'Yarn production / spinning mill': {
    visibleWasteVolumeQuestionNames: ['totalWasteTonnes', 'softWasteTonnes', 'hardWasteTonnes'],
    visibleQuestionValidation: [
      {
        names: ['totalWasteTonnes', 'softWasteTonnes', 'hardWasteTonnes'],
        gt: 4,
        lt: 31,
      },
    ],
    identifier: 'SPINNING', // used for translation context
  },
  'Fabric Mill (woven)': {
    visibleWasteVolumeQuestionNames: ['smallWasteTonnes', 'rejectedFabric'],
    visibleQuestionValidation: [
      {
        names: ['smallWasteTonnes'],
        gt: 0,
        lt: 26,
      },
    ],
    identifier: 'WOVEN',
  },
  'Fabric Mill (knit)': {
    visibleWasteVolumeQuestionNames: ['smallWasteTonnes', 'rejectedFabric'],
    visibleQuestionValidation: [
      {
        names: ['smallWasteTonnes'],
        gt: 0,
        lt: 26,
      },
    ],
    identifier: 'KNIT',
  },
  'Garment / apparel manufacturing (CMT/RMG)': {
    visibleWasteVolumeQuestionNames: [
      'cuttingWasteTonnes',
      'mixedTextileTonnes',
      'rejectedFabric',
      'rejectedProductPieces',
    ],
    visibleQuestionValidation: [
      {
        names: ['cuttingWasteTonnes', 'mixedTextileTonnes'],
        gt: 4,
        lt: 31,
      },
    ],
    identifier: 'CMT_RMG',
  },
  'Tannery, leather production': {
    visibleWasteVolumeQuestionNames: ['totalWasteTonnes', 'rejectedProductPieces'],
    visibleQuestionValidation: [
      {
        names: ['totalWasteTonnes'],
        gt: 0,
        lt: 51,
      },
    ],
    identifier: 'LEATHER',
  },
  'Manufacturing of other ready-made products (footwear, accessories)': {
    visibleWasteVolumeQuestionNames: ['totalWasteTonnes', 'rejectedProductPieces'],
    visibleQuestionValidation: [
      {
        names: ['totalWasteTonnes'],
        gt: 0,
        lt: 51,
      },
    ],
    identifier: 'OTHER_READY_MADE',
  },
  'Wet processes (dyeing/washing)': {
    visibleWasteVolumeQuestionNames: ['mixedTextileTonnes', 'rejectedFabric', 'rejectedProductPieces'],
    visibleQuestionValidation: [
      {
        names: ['mixedTextileTonnes'],
        gt: -1,
        lt: 51,
      },
    ],
    identifier: 'WET_PROCESS',
  },
  'Printing, embroidery': {
    visibleWasteVolumeQuestionNames: ['mixedTextileTonnes', 'rejectedFabric', 'rejectedProductPieces'],
    visibleQuestionValidation: [
      {
        names: ['mixedTextileTonnes'],
        gt: 0,
        lt: 51,
      },
    ],
    identifier: 'PRINTING_EMBROIDERY',
  },
  'Textile Linens and homewear': {
    visibleWasteVolumeQuestionNames: ['mixedTextileTonnes', 'rejectedFabric', 'rejectedProductPieces'],
    visibleQuestionValidation: [
      {
        names: ['mixedTextileTonnes'],
        gt: 0,
        lt: 51,
      },
    ],
    identifier: 'LINEN_HOMEWEAR',
  },
}

export const TANNERY_AND_LEATHER_PRODUCTION = 'Tannery, leather production'

export interface VisibleQuestionValidationItem {
  names: string[]
  gt: number
  lt: number
}

export interface QuestionConfigurationItem {
  visibleWasteVolumeQuestionNames: string[]
  visibleQuestionValidation?: VisibleQuestionValidationItem[]
  identifier: string
}

export const useActivityWasteVolumeDetails = (activityName: string): QuestionConfigurationItem | undefined => {
  // TODO: move to backend/classifiers
  return ACTIVITY_WASTE_VOLUME_DETAILS[activityName]
}

export const useGetProfileProductionCompositionsByMaterialType = () => {
  const { data: productionTypes } = useClassifierOptions('productionTypes')
  const { data: profileProductionCompositions } = useClassifierOptions('profileProductionCompositions')

  return useCallback(
    (materialTypeId: number) => {
      const productionCompositionIds = uniq(
        productionTypes
          ?.filter((t) => t.productionMaterialTypeIds.includes(materialTypeId))
          .map((t) => t.profileProductionCompositionIds)
          .flat(),
      )

      return profileProductionCompositions?.filter((c) => productionCompositionIds.includes(Number(c.id)))
    },
    [productionTypes, profileProductionCompositions],
  )
}

export const useGetRawMaterialCode = () => {
  const rawMaterialCodes = useClassifierOptionList('rawMaterialCodes', 'id', 'code')
  const defaultCode = useMemo(() => rawMaterialCodes?.find((code) => code.isMixed), [rawMaterialCodes])
  return useCallback(
    (compositionId, materialStageId) => {
      const code = rawMaterialCodes?.find(
        (code) => code.materialStageId === Number(materialStageId) && code.compositionId === Number(compositionId),
      )
      return code ? code : defaultCode
    },
    [rawMaterialCodes, defaultCode],
  )
}

export const useAllProfileProductionCompositions = () => {
  const { data: classifiers } = useClassifiers()
  return classifiers?.profileProductionCompositions
}

export const useProductionWasteTypes = (productionTypeIds: number[]) => {
  const { data: classifierOptions }: { data: any } = useClassifierOptions('productionWasteTypes')
  const { data: classifiers }: { data: any } = useClassifiers()

  return useMemo(() => {
    const wasteTypeIds = classifiers?.productionTypes
      .filter((t) => productionTypeIds?.includes(t.id))
      .map((t) => t.productionWasteTypeIds)
      .flat()
    return classifierOptions.filter((t) => wasteTypeIds.includes(Number(t.id)))
  }, [classifiers?.productionTypes, classifierOptions, productionTypeIds])
}

export const useMaterialProductionWasteTypes = (productionMaterialTypeId: number) => {
  const { data: classifiers }: { data: any } = useClassifiers()
  const { data: productionWasteTypes }: { data: any } = useClassifierOptions('productionWasteTypes')
  return useMemo(() => {
    const wasteTypeIds =
      classifiers?.productionTypes
        ?.filter((t) => t.productionMaterialTypeIds?.includes(productionMaterialTypeId))
        ?.flatMap((t) => t.productionWasteTypeIds) ?? []
    return productionWasteTypes.filter((t) => wasteTypeIds.includes(Number(t.id)))
  }, [classifiers?.productionTypes, productionWasteTypes, productionMaterialTypeId])
}

export const useProductionWasteType = (productionWastetypeId: number) => {
  const { data: productionWasteTypes }: { data: any } = useClassifierOptions('productionWasteTypes')
  return useMemo(
    () => productionWasteTypes.find((t) => Number(t.id) === productionWastetypeId),
    [productionWasteTypes, productionWastetypeId],
  )
}

export const useProductionWasteSubtypes = (productionWastetypeId: number) => {
  const getProductionWasteSubtypeGroups = useGetClassifierSubOptions(
    'productionWasteSubtypeGroups',
    'id',
    'productionWasteSubtypes',
    'productionWasteSubtypes',
    'id',
    'name',
  )
  const productionWasteType = useProductionWasteType(productionWastetypeId)
  return useMemo(() => {
    return getProductionWasteSubtypeGroups(productionWasteType?.subtypeGroupId?.toString()) || []
  }, [productionWasteType, getProductionWasteSubtypeGroups])
}

export const useAllProductionWasteSubtypes = () => {
  const { data: classifiers }: { data: any } = useClassifiers()
  return useMemo(
    () => classifiers?.productionWasteSubtypeGroups?.map((g) => g.productionWasteSubtypes)?.flat() ?? [],
    [classifiers?.productionWasteSubtypeGroups],
  )
}

export const useColorChemicals = () => {
  return useClassifierOptionList('colorChemicals')
}

export const useExternalWasteDisposalTypes = () => {
  const { data: wasteDisposalTypes } = useClassifierOptions('wasteDisposalTypes')
  return useMemo(() => wasteDisposalTypes?.filter((t) => !t.isInHouse && !t.isRecyclerExclusive), [wasteDisposalTypes])
}

export const useInternalWasteDisposalTypes = () => {
  const { data: wasteDisposalTypes } = useClassifierOptions('wasteDisposalTypes')
  return useMemo(() => wasteDisposalTypes?.filter((t) => t.isInHouse && !t.isRecyclerExclusive), [wasteDisposalTypes])
}

export const useHandlerServiceDisposalTypes = (wasteDisposalServiceIds: number[]) => {
  const getWasteDisposalServices = useGetClassifierObject('wasteDisposalServices', 'id')
  const translateDisposalTypes = useClassifiersTranslation('wasteDisposalTypes')

  return useMemo(() => {
    const disposalTypeOptions =
      [...new Set([...wasteDisposalServiceIds])]
        .flatMap((id) => getWasteDisposalServices(Number(id))?.disposalTypes ?? [])
        .filter((t) => !t?.isDeprecated && !t?.isRecyclerExclusive) ?? []

    const translatedDisposalTypeOptions = disposalTypeOptions?.map((o) => ({
      ...o,
      name: translateDisposalTypes(o.name, o.name),
    }))

    return translatedDisposalTypeOptions
  }, [wasteDisposalServiceIds, getWasteDisposalServices, translateDisposalTypes])
}

function getChangedFormValues<Values extends FormikValues>(
  values: Values,
  initialValues: Values | null | undefined,
): Values {
  if (initialValues === null || initialValues === undefined) {
    return values
  }

  return pickBy(values, (value, key) => {
    let initialValue = initialValues[key]
    // workaround for productionColorClassifications where items with 0 sharePercent are discarded on the backend
    initialValue = initialValue?.filter?.((v) => v?.sharePercent !== 0)

    // normalize empty values
    initialValue = initialValue?.length === 0 || initialValue === '' || initialValue === undefined ? null : initialValue
    value = value?.length === 0 || value === '' || value === undefined ? null : value

    return !isEqual(initialValue, value)
  }) as Values
}

/** Wraps an usual callback provided by `useHandleFormSubmit` and sends a third argument, initialValues, which modifies its behaviour */
export const useSaveWithInitialValues = <Values extends FormikValues>(
  save: (values: Values, formikHelpers: FormikHelpers<Values>, initialValues?: Values) => void | Promise<void>,
  initialValues: Values,
  extraValues?: object,
) =>
  useCallback(
    (values, formikHelpers) => save(merge(values, extraValues, {}), formikHelpers, initialValues),
    [save, initialValues, extraValues],
  )

export const useHandleFormSubmit = <Values extends FormikValues>(
  onSubmit: (values: Values) => Promise<any>,
  onSuccess?: (response: any, values: Values) => void,
  forwardError = false,
  reset?: boolean,
) =>
  useCallback(
    async (
      values: Values,
      { setErrors, setTouched, resetForm, setSubmitting }: FormikHelpers<Values>,
      initialValues?: Values, // if this is provided, will filter sent payload with only changed values
    ) => {
      try {
        setSubmitting(true) // in case the callback was invoked separate from form submit
        const normalizedValues = convertEmptyStringsToNulls(values) // in case the callback was invoked separate from form submit

        // Only send changed values if initialValues are provided, to restrict backend validation
        const response = await onSubmit(getChangedFormValues(normalizedValues, initialValues))
        if (onSuccess !== undefined) {
          onSuccess(response, values)
        }
        /* If 'strip_whitespace=True' is set on the backend object,
          any surrounding white spaces around the form inputs will be removed on submit.
          However, if the only change made to the inputs were white spaces,
          Formik will not automatically trigger a reset of the form inputs.
          In this case, a manual reset is required. */
        if (reset) {
          resetForm()
        }
      } catch (e: any) {
        // Set fields touched during submit so the errors display
        // This is done automatically on submit but not when bypassing submit
        // Second argument disables re-validation for setTouched as it conflicts with setErrors
        if (e.detail) {
          setTouched(setNestedObjectValues(e.detail, true), false)
          setErrors(e.detail)
        } else {
          setErrors(e.message)
        }
        if (forwardError) {
          throw e
        }
      } finally {
        setSubmitting(false) // in case the callback was invoked separate from form submit
      }
    },
    [onSubmit, onSuccess, forwardError, reset],
  )

export const useHandleFormAction = <T, U>(
  onSubmit: (values: T) => Promise<U>,
  onSuccess?: (response: U, values: T) => void,
) => {
  const { setErrors, setSubmitting, setFieldTouched } = useFormikContext<T>()

  return useCallback(
    async (values: T) => {
      try {
        setSubmitting(true) // this forces FastFields to update in related form elements
        const response = await onSubmit(values)
        if (onSuccess !== undefined) {
          onSuccess(response, values)
        }
      } catch (e: any) {
        // workaround for formik not displaying errors on not touched fields
        // happens when a field becomes required only after setting the entity status to "confirmed"
        Object.keys(e.detail).map((key) => setFieldTouched(key, true, false))
        setErrors(e.detail)
      } finally {
        setSubmitting(false)
      }
    },
    [onSubmit, onSuccess, setErrors, setSubmitting, setFieldTouched],
  )
}

export const useToggle = (initialValue: boolean = false) => {
  const [state, setState] = useState<boolean>(initialValue)
  return useMemo(() => [state, () => setState(!state)] as const, [state, setState])
}

export const useProfileProductionTypes = () => {
  const productionTypes = useClassifierOptionFilteredList('productionTypes')
  return useMemo(() => productionTypes?.filter((t) => !t.isDefault), [productionTypes])
}

export const useProjects = () => {
  const { data: classifiers }: { data: any } = useClassifiers()

  return useMemo(() => sortBy(classifiers?.projects, 'name'), [classifiers?.projects])
}

export const useCurrentProject = () => {
  const [roleQuery] = useActiveRole()
  return { ...roleQuery, data: roleQuery.data?.projects?.[0] } as UseQueryResult<
    CurrentUser['activeRole']['projects'][number],
    ErrorResponse
  >
}

export const useCurrentFacility = () => {
  const [{ data: role, isLoading }] = useActiveRole()
  const facility = role?.facilities?.[0]
  const { isFactory, isHandler, isRecycler } = checkFacilityType(facility)
  return { facilityId: facility?.id, facility, isFactory, isHandler, isRecycler, isLoading }
}

export const checkFacilityType = (facility?: CurrentFacility) => {
  const isFactory = facility?.typeId === FacilityType.Manufacturer
  const isHandler = facility?.typeId === FacilityType.WasteHandler
  const isRecycler = facility?.typeId === FacilityType.Recycler
  return { isFactory, isHandler, isRecycler }
}

export function useIsBrand() {
  const [role] = useActiveRole()
  return role.data?.roleId === RoleId.BrandManager
}

export function useIsBrandWithoutActiveMembership() {
  const isActiveMember = useIsActiveMemberBrand()
  return isActiveMember
}

export function useIsActiveMemberBrand() {
  const [activeRole] = useActiveRole()
  return !activeRole?.data?.brands?.[0]?.membership?.isActive
}

export function useIsFacilityWithoutActiveMembership() {
  const currentFacility = useCurrentFacility()
  const isActiveMember = useIsActiveMember()

  return !currentFacility.isFactory && !isActiveMember
}

export function useIsActiveMember() {
  const [activeRole] = useActiveRole()
  return !!activeRole?.data?.facilities?.[0]?.hasActiveMembership
}

export function useGetPaywallClassNames() {
  if (!useIsActiveMember()) {
    return 'blur-sm select-none'
  }
}

export function useRecyclerWithNonActiveMembership() {
  const facilityQuery = useCurrentFacility()
  const isActiveMember = useIsActiveMember()
  return facilityQuery?.isRecycler && !isActiveMember
}

export const useCurrentBrand = () => {
  const [roleQuery] = useActiveRole()
  return { ...roleQuery, data: roleQuery.data?.brands?.[0] } as UseQueryResult<
    CurrentUser['activeRole']['brands'][number],
    ErrorResponse
  >
}

export const useFacilityData = (facilityId?: number | string) => {
  const facilityQuery = useFacility(facilityId)
  const typeId = facilityQuery.data?.facility?.typeId
  const isFactory = typeId === FacilityType.Manufacturer
  const isHandler = typeId === FacilityType.WasteHandler
  const isRecycler = typeId === FacilityType.Recycler
  return { ...facilityQuery, isFactory, isHandler, isRecycler }
}

export const useProductionCertificates = (onlyCategoryLevel = false) => {
  const { data: productionCertificates }: { data: any } = useClassifierOptions('productionCertificates')
  return useMemo(
    () => productionCertificates?.filter((c) => !onlyCategoryLevel || !c.isFacilityLevel),
    [productionCertificates, onlyCategoryLevel],
  )
}

export const usePartnerOptions = (facilityId: number, facilityTypes: string[]) => {
  const classifierQuery = useClassifiers()
  const getFacilityType = useGetClassifier('facilityTypes', 'id', 'name')

  return useFacilityPartnerOptions(facilityId, facilityTypes, {
    enabled: classifierQuery.isSuccess,
    select: (data) =>
      data.partnerOptions
        .map(({ id, name, organization, typeId, deletedAt, countryId }) => ({
          id,
          name: `${organization.name}/${name} (${getFacilityType(typeId) ?? typeId})`,
          typeId,
          deletedAt,
          countryId,
        }))
        .sort((a, b) => a.name.localeCompare(b.name)),
  })
}

export function useGroupedPartnerOptions(partners) {
  const getCountryName = useGetClassifier('countries', 'id', 'name')

  return useMemo(() => {
    return Object.entries(groupBy(partners, 'countryId'))
      .map(([countryId, partners]) => ({
        name: getCountryName(countryId) ?? '',
        label: getCountryName(countryId) ?? '',
        value: 'countryId',
        id: countryId,
        options: partners.map(({ id, name, ...rest }) => ({
          id,
          label: name,
          name: name,
          value: id.toString(),
          ...rest,
        })),
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  }, [partners, getCountryName])
}

export function useGetDelayedRedirectOnSuccessHandler<T extends Array<any>>(
  url: string,
  handleSubmit: (...args: T) => Promise<any>,
  timeout: number,
) {
  const navigate = useNavigate()
  const timeouts = useRef([-1])

  useEffect(
    () => () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      // it is ok to change ref value before cleanup when it is not related to DOM nodes
      timeouts.current.forEach(clearInterval)
    },
    [],
  )

  return useCallback(
    (nextUrlSuffix = null) =>
      async (...args: T) => {
        try {
          await handleSubmit(...args)
          if (nextUrlSuffix) {
            timeouts.current.push(window.setTimeout(() => navigate(`${url}/${nextUrlSuffix}`), timeout))
          }
        } catch (e) {
          // ignoring on purpose
        }
      },
    [handleSubmit, navigate, url, timeout],
  )
}

interface ShipmentTargetOption {
  value: string
  id: number
  label: string
  isProfileHandler: boolean
}

export function useShipmentTargetOptions(facilityId: number) {
  const { t } = useTranslation()
  const handlerQuery = useFactoryProfileHandlers(facilityId, { useErrorBoundary: false })
  return useMemo(() => {
    const handlerOptions =
      handlerQuery.data?.handlers
        ?.filter((h) => !h.isArchived)
        ?.map(({ id, name, contact }) => ({
          id,
          name: t('{{facilityName}} ({{contact}}) (not on platform)', { facilityName: name, contact }),
          isProfileHandler: true,
        })) ?? []

    const partnerOptions = handlerQuery.data?.partnerFacilities
      ?.filter((f) => !f.isDeleted)
      .map(({ id, name, organization }) => ({
        id,
        name: `${organization.name}/${name}`,
        isProfileHandler: false,
      }))

    const combinedOptions = handlerOptions
      ?.concat(partnerOptions ?? [])
      ?.map(({ id, name, isProfileHandler }, index) => ({ value: index.toString(), label: name, id, isProfileHandler }))

    return { ...handlerQuery, data: combinedOptions } as UseQueryResult<ShipmentTargetOption[], ErrorResponse>
  }, [handlerQuery, t])
}

interface FacilityReportCategoryTargetOption {
  value: string
  label: string
  partnerFacilityId?: number
  factoryProfileHandlerId?: number
  wasteDisposalTypeId?: number
  descriptionRequired?: boolean
}

export function useFacilityReportCategoryTargetOptions(facilityId?: number) {
  const { t } = useTranslation()
  const handlerQuery = useFactoryProfileHandlers(facilityId, { useErrorBoundary: false, enabled: !!facilityId })
  const { data: wasteDisposalTypes }: { data: any } = useClassifierOptions('wasteDisposalTypes')

  return useMemo(() => {
    const wasteDisposalOptions = (wasteDisposalTypes || [])
      .filter((t) => t.isInHouse && !t.isRecyclerExclusive)
      .map(({ name, id, descriptionRequired }) => ({
        label: t('In-house disposal: {{disposalTypeName}}', { disposalTypeName: name }),
        wasteDisposalTypeId: id,
        descriptionRequired,
      }))

    const partnerOptions = handlerQuery.data?.partnerFacilities?.map(({ id, name, organization }) => ({
      partnerFacilityId: id,
      label: t('Sent to {{organizationName}}/{{facilityName}}', {
        organizationName: organization.name,
        facilityName: name,
      }),
    }))

    const handlerOptions =
      handlerQuery.data?.handlers?.map(({ id, name, contact }) => ({
        factoryProfileHandlerId: id,
        label: t('Sent to {{facilityName}} ({{contact}})', { facilityName: name, contact }),
      })) ?? []

    const combinedOptions = wasteDisposalOptions
      .concat(partnerOptions)
      .concat(handlerOptions)
      .map((option, index) => ({
        value: index,
        partnerFacilityId: null,
        factoryProfileHandlerId: null,
        wasteDisposalTypeId: null,
        ...option,
      }))

    return { ...handlerQuery, data: combinedOptions } as UseQueryResult<
      FacilityReportCategoryTargetOption[],
      ErrorResponse
    >
  }, [wasteDisposalTypes, handlerQuery, t])
}

export function useFacilityReportBrandOptions(facilityId?: number) {
  const { t } = useTranslation()
  const brandQuery = useFactoryProfileBrands(facilityId)
  const classifierQuery = useClassifiers()

  return useMemo(() => {
    const profileBrands = brandQuery.data?.brands ?? []
    const profileBrandIds = profileBrands.map((b) => b.brandId.toString()) ?? []

    const options = partition(
      classifierQuery.data?.brands ?? [],
      (b) => profileBrandIds.includes(b.id) && b.name !== 'Other',
    ).map((brands, index) => ({
      label: index === 0 ? t('In profile') : t('Other'),
      options: brands.map((b) => ({ ...b, label: b.name, value: b.id })),
    }))

    return { ...brandQuery, data: options }
  }, [brandQuery, classifierQuery, t])
}

export function useFacilityBrandOptionsFromProfile(facilityId?: number) {
  const classifierQuery = useClassifiers()
  return useFactoryProfileBrands(facilityId, {
    enabled: classifierQuery.isFetched && !!facilityId,
    select: (data) => {
      const profileBrands = data.brands ?? []
      const profileBrandIds = profileBrands.map((b) => b.brandId.toString()) ?? []
      return (classifierQuery.data?.brands ?? []).filter((b) => b.name !== 'Other' && profileBrandIds.includes(b.id))
    },
  })
}

export function useGroupedRecyclerActivityOptions() {
  const { data: facilityRecyclingStages } = useClassifierOptions('facilityRecyclingStages')
  return useMemo(() => {
    return facilityRecyclingStages?.filter((a) => a.appliesToRecycler)
  }, [facilityRecyclingStages])
}

export function useHandlerRecyclingActivityOptions() {
  const classifierQuery = useClassifiers()
  return useMemo(() => {
    return classifierQuery.data?.facilityRecyclingStages
      .flatMap(({ activities }) => activities)
      .filter((a) => a.appliesToHandler)
      .map(({ id, name, descriptionRequired }) => ({ label: name, value: id, descriptionRequired }))
  }, [classifierQuery.data])
}

export function useGroupedHandlerRecyclingActivityOptions() {
  const { data: facilityRecyclingStages } = useClassifierOptions('facilityRecyclingStages')
  const getFacilityRecyclingStages = useGetClassifierSubOptions(
    'facilityRecyclingStages',
    'id',
    'name',
    'activities',
    'id',
    'name',
  )
  return useMemo(() => {
    return facilityRecyclingStages
      ?.filter((a) => a.appliesToHandler)
      ?.map(({ id, name, activities, ...rest }) => ({
        label: name,
        value: id,
        ...rest,
        options: (getFacilityRecyclingStages(id as any) || [])
          .filter((a) => a.appliesToHandler)
          .map(({ id, name, descriptionRequired, subActivities }) => ({
            label: name,
            value: id,
            descriptionRequired,
            subActivities,
          })),
      }))
  }, [facilityRecyclingStages, getFacilityRecyclingStages])
}

export const useMaterialSourceOptions = () => {
  return useClassifierOptionList('materialSources')
}

export const useProfileMaterialOriginOptions = () => {
  return useClassifierOptionList('profileMaterialOrigins')
}

export const useProfilePreferenceOptions = () => {
  return useClassifierOptionList('profilePreferences')
}

const NON_ACCEPTABLE_FILTER = {
  redList: ['NOT_ACCEPTABLE'],
  filterAttribute: 'id',
}
export const useProfilePreferenceAcceptableOptions = () => {
  return useFilteredClassifierOptionList('profilePreferences', 'id', 'name', NON_ACCEPTABLE_FILTER)
}

export const useProductGradeOptions = () => {
  const { data: classifiers }: { data: any } = useClassifiers()
  return classifiers?.productGrades
}

export const useRecyclerOutputEndProductTypeOptions = () => {
  return useClassifierOptionList('recyclerOutputEndProductTypes')
}

export const useRecyclerOutputProductCompositionOptions = () => {
  return useClassifierRichOptionList('recyclerOutputProductCompositions')
}

export const useRecyclerOutputProductColorOptions = () => {
  return useClassifierOptionList('recyclerOutputProductColors')
}

export const useRecyclerOutputProductApplicationOptions = () => {
  return useClassifierOptionList('recyclerOutputProductApplications')
}

export const useRecyclerOutputProductSpecificationOptions = () => {
  return useClassifierOptionList('recyclerOutputProductSpecifications')
}

export const useRecyclerProfileApproximateYearOptions = () => {
  const { data: classifiers }: { data: any } = useClassifiers()
  return classifiers?.recyclerProfileApproximateYears
}

export const useHandlerProfileApproximateYearOptions = () => {
  const { data: classifiers }: { data: any } = useClassifiers()
  return classifiers?.handlerProfileApproximateYears
}

export const useRecyclerProfileClientRequestAvailabilityOptions = () => {
  const classifiers = useClassifierOptionList('recyclerProfileClientRequestAvailabilities', 'id', 'name')
  return classifiers
}

export const useHandlerSortingInputCategories = (categoryTypeId?: number | string) => {
  const getHandlerSortingInputCategorySubTypes = useGetClassifierSubOptions(
    'handlerSortingInputCategoryTypes',
    'id',
    'name',
    'categories',
    'id',
    'name',
  )
  return getHandlerSortingInputCategorySubTypes(categoryTypeId as any)
}

export const useActiveHandlerSortingInputCategoryTypes = () => {
  const categoryTypeOptions = useClassifierOptionList('handlerSortingInputCategoryTypes')
  return useMemo(() => {
    return categoryTypeOptions.filter((c) => !c.isDeprecated)
  }, [categoryTypeOptions])
}

export const useHandlerOutputCategories = (categoryTypeId?: number | string) => {
  const getHandlerOutputCategorySubTypes = useGetClassifierSubOptions(
    'handlerOutputCategoryTypes',
    'id',
    'name',
    'categories',
    'id',
    'name',
  )
  return useMemo(
    () => getHandlerOutputCategorySubTypes(categoryTypeId as number),
    [categoryTypeId, getHandlerOutputCategorySubTypes],
  )
}

export const useFacilityRecyclingStageActivities = (recyclingStageId: number) => {
  const getFacilityRecyclingStageActivities = useGetClassifierSubOptions(
    'facilityRecyclingStages',
    'id',
    'name',
    'activities',
    'id',
    'name',
  )
  return useMemo(
    () => getFacilityRecyclingStageActivities(recyclingStageId.toString()),
    [recyclingStageId, getFacilityRecyclingStageActivities],
  )
}

export const useInventoryDecrementModificationTypeOptions = () => {
  const classifiers = useClassifierOptionList('categoryInventoryModificationTypes')
  const options = useMemo(() => classifiers.filter((t) => !t.isIncrement && t.isUserSelectable), [classifiers])
  return options
}

export const useInventoryIncrementModificationTypeOptions = () => {
  const classifiers = useClassifierOptionList('categoryInventoryModificationTypes')
  const options = useMemo(() => classifiers.filter((t) => t.isIncrement && t.isUserSelectable), [classifiers])
  return options
}

export function useWithStringIds<T extends { id: number }>(
  values: T[] | undefined,
): (Omit<T, 'id'> & { id: string })[] {
  return useMemo(
    () =>
      values?.map(({ id, ...v }: T) => {
        return { ...v, id: id.toString() }
      }) ?? [],
    [values],
  )
}

/**
 * Return a subset of initial values according to default values for form initialization
 * @param defaultValues fallback values to be used if they do not exist in @param existingValues
 * @param existingValues data to initialize with, only top-level keys matching @param defaultValues are used
 * @param extraValues extra data to add to form
 * @returns resolved values
 */
export function useInitialFormValues<T extends object = object, U extends object = object>(
  defaultValues: T,
  existingValues: Partial<T>,
  extraValues: U = {} as U,
): T & U {
  return useMemo(
    () =>
      Object.assign(
        {},
        defaultValues,
        pickBy(existingValues, (v, k) => k in defaultValues && v !== undefined && !(isArray(v) && v.length === 0)),
        extraValues,
      ),
    [defaultValues, existingValues, extraValues],
  )
}

export function useRolesByEntity(entity: keyof Classifiers['roleIdsByEntity']) {
  const classifierQuery = useClassifiers()
  const data = useMemo(
    () =>
      (classifierQuery.data?.roles ?? []).filter((r) =>
        (classifierQuery.data?.roleIdsByEntity?.[entity] ?? []).includes(r.id),
      ),
    [entity, classifierQuery.data],
  )
  return { ...classifierQuery, data }
}

export const useFacilityTypes = () => useClassifiers().data?.facilityTypes

type ListAttributeKey<T extends object, X extends object = {}> = keyof {
  [K in keyof T as T[K] extends X[] ? K : never]
}

export function useClassifierOptions<
  K extends ListAttributeKey<Classifiers>,
  V extends Classifiers[K][number],
  S extends keyof V,
  N extends keyof V,
>(classifierName: K, valueAttribute: S, name: N): UseQueryResult<(V & { id: string; name: string })[], ErrorResponse>
export function useClassifierOptions<
  K extends ListAttributeKey<Classifiers, { id: number | string; name: string }>,
  V extends Classifiers[K][number],
>(classifierName: K): UseQueryResult<(Omit<V, 'id'> & { id: string; name: string })[], ErrorResponse>
export function useClassifierOptions<K extends ListAttributeKey<Classifiers>>(
  classifierName: K,
  valueAttribute = 'id',
  name = 'name',
) {
  const translateClassifiers = useClassifiersTranslation(classifierName)

  return useClassifiers({
    select: (c: Classifiers) =>
      c[classifierName].map((i) => ({
        ...i,
        id: i[valueAttribute].toString(),
        name: translateClassifiers(i[name].toString(), { defaultValue: i[name].toString() }),
        originalName: i[name].toString(),
      })),
  })
}

export function useClassifierIdsFromNames<K extends ListAttributeKey<Classifiers>>(
  namesList: string[],
  classifierName: K,
) {
  const { data: classifiers }: { data: any } = useClassifiers()
  return useMemo(() => {
    return classifiers?.[classifierName]?.filter((g) => namesList.includes(g.name))?.map((g) => g.id.toString())
  }, [classifiers, classifierName, namesList])
}

export function useClassifierOptionList<
  K extends ListAttributeKey<Classifiers>,
  V extends Classifiers[K][number],
  CS extends keyof V,
>(
  classifierName: K,
  valueAttribute: CS = 'id' as CS,
  name: CS = 'name' as CS,
): (Omit<Classifiers[K][number], 'id'> & { id: string })[] | [] {
  const query = useClassifierOptions(classifierName, valueAttribute, name)
  const emptyList: typeof query.data = useMemo(() => [], [])
  return useMemo(() => (query.data?.length ? query.data : emptyList), [query.data, emptyList])
}

export function useClassifierActiveOptionList<K extends ListAttributeKey<Classifiers>>(
  classifierName: K,
  valueAttribute = 'id',
  name = 'name',
): (Omit<Classifiers[K][number], 'id'> & { id: string })[] | [] {
  const query = useClassifierOptions(classifierName, valueAttribute, name)
  const emptyList: typeof query.data = useMemo(() => [], [])
  return useMemo(
    () => (query.data?.length ? query.data.filter((c) => !c?.isDeprecated) : emptyList),
    [query.data, emptyList],
  )
}

// Used for RichSelectFormField
export function useClassifierRichOptionList<K extends ListAttributeKey<Classifiers>>(
  classifierName: K,
  valueAttribute = 'id',
  name = 'name',
): (Omit<Classifiers[K][number], 'id'> & { id: string })[] | [] {
  const query = useClassifierOptions(classifierName, valueAttribute, name)
  const emptyList: typeof query.data = useMemo(() => [], [])
  return useMemo(
    () =>
      query.data?.length
        ? query.data
            .filter((c) => !c.isDeprecated)
            .map((c) => ({
              ...c,
              value: c.id.toString(),
              label: c.name,
            }))
        : emptyList,
    [query.data, emptyList],
  )
}

export interface ClassifierFilter {
  filterAttribute: string
  redList?: string[]
  greenList?: string[]
}

export function useFilteredClassifierOptionList<
  K extends ListAttributeKey<Classifiers>,
  V extends Classifiers[K][number],
  CS extends keyof V,
>(
  classifierName: K,
  valueAttribute = 'id' as CS,
  name = 'name' as CS,
  filter: ClassifierFilter = { filterAttribute: 'id' },
): (Classifiers[K][number] & {
  id: string
})[] {
  const { redList, greenList, filterAttribute } = filter
  const options = useClassifierOptionList(classifierName, valueAttribute, name)
  const preferenceOptions = useMemo(() => {
    return options?.filter(
      (option) =>
        !redList?.includes(String(option[filterAttribute])) &&
        (greenList ? greenList.includes(String(option[filterAttribute])) : true),
    )
  }, [options, greenList, redList, filterAttribute])
  return preferenceOptions
}

export function useClassifierOptionFilteredList<K extends ListAttributeKey<Classifiers>>(
  classifierName: K,
  valueAttribute = 'id',
  name = 'name',
): (Omit<Classifiers[K][number], 'id'> & { id: string })[] | [] {
  // Hacky rush workaround to hide deprecated options from UI
  // TODO: Remove this after classifiers have been removed from back end
  const options: any = useClassifierOptions(classifierName, valueAttribute, name).data
  const classifiersToCheck = ['colorClassifications', 'productionTypes']
  const shouldNotFilter = !classifiersToCheck.includes(classifierName)

  return useMemo(() => {
    const namesToRemove = [
      'Fibre production',
      'Black (solid color)',
      'Indigo / navy / blue (solid color)',
      'Dark colors',
      'All kinds of other solid colors',
      'Yarn-dyed yarns or fabrics',
      'Printed fabrics',
    ]

    return shouldNotFilter ? options : options?.filter((option) => !namesToRemove.includes(option.originalName.trim()))
  }, [shouldNotFilter, options])
}

export function useClassifierOptionIdKeyValue<K extends ListAttributeKey<Classifiers>>(
  classifierName: K,
  valueAttribute = 'id',
  name = 'name',
): (Omit<Classifiers[K][number], 'id'> & { id: string }) | {} {
  const productionTypes = useClassifierOptionList(classifierName, valueAttribute, name)
  return useMemo(() => {
    const productionTypesObj = {}
    productionTypes.forEach((type) => {
      productionTypesObj[type.id] = type
    })
    return productionTypesObj
  }, [productionTypes])
}

export function useGetClassifierSubOptions<
  K extends ListAttributeKey<Classifiers>,
  V extends Classifiers[K][number],
  SK extends ListAttributeKey<V>,
  CS extends keyof V,
  SV extends V[SK][number],
  S extends keyof SV,
  N extends keyof SV,
>(
  classifierName: K,
  valueAttribute: CS,
  name: CS,
  subClassifierName: SK,
  subValueAttribute: S,
  subName: N,
  filter: ClassifierFilter = { filterAttribute: 'id' },
): (a: V[CS]) => (SV & { id: string; name: string })[] | undefined {
  const translationsClassifiers = useClassifiersTranslation(classifierName + '`' + String(subClassifierName))

  const options = useFilteredClassifierOptionList(classifierName, valueAttribute, name, filter)
  const { redList, greenList, filterAttribute } = filter

  return useCallback(
    (value) =>
      options
        ?.find((i) => i[valueAttribute] === value)
        ?.[subClassifierName]?.map((i) => ({
          ...i,
          id: i[subValueAttribute].toString(),
          name: translationsClassifiers(i[subName].toString(), { defaultValue: i[subName].toString() }),
        }))
        ?.filter(
          (option) =>
            !redList?.includes(String(option[filterAttribute])) &&
            (greenList ? greenList.includes(String(option[filterAttribute])) : true),
        ),
    [
      options,
      valueAttribute,
      subClassifierName,
      subValueAttribute,
      subName,
      translationsClassifiers,
      redList,
      greenList,
      filterAttribute,
    ],
  )
}

export function useGetClassifierSubObject<
  K extends ListAttributeKey<Classifiers>,
  V extends Classifiers[K][number],
  CS extends keyof V,
  SK extends ListAttributeKey<V>,
  SV extends V[SK][number],
  S extends keyof SV,
  N extends keyof SV,
>(
  classifierName: K,
  valueAttribute: CS,
  subClassifierName: SK,
  subValueAttribute: S,
  subNameAttribute: N,
): (a: V[CS], b: SV[S]) => (SV & { id: string; name: string }) | undefined {
  const getClassifier = useGetClassifierObject(classifierName, valueAttribute)
  const translateClassifiers = useClassifiersTranslation(classifierName + '`' + String(subClassifierName))

  return useCallback(
    (value, subValue) => {
      const item = getClassifier(value)?.[subClassifierName]?.find((r) => r?.[subValueAttribute] === subValue)
      return item
        ? {
            ...item,
            id: item[subValueAttribute].toString(),
            name: translateClassifiers(item[subNameAttribute].toString(), {
              defaultValue: item[subNameAttribute].toString(),
            }),
          }
        : undefined
    },
    [subClassifierName, subValueAttribute, subNameAttribute, getClassifier, translateClassifiers],
  )
}

export function useGetClassifierObject<
  K extends ListAttributeKey<Classifiers>,
  V extends Classifiers[K][number],
  S extends keyof V,
>(classifierName: K, searchByAttribute: S) {
  const translateClassifiers = useClassifiersTranslation(classifierName)

  const data = useClassifiers().data

  return useCallback(
    (key: V[S]): V | undefined => {
      const classifier = data?.[classifierName]?.find((d) => d[searchByAttribute] === key)
      if (classifier) {
        return {
          ...classifier,
          name: translateClassifiers(classifier.name, { defaultValue: classifier.name }),
          originalName: classifier.name,
        }
      }
      return classifier
    },
    [data, classifierName, searchByAttribute, translateClassifiers],
  )
}

export function useGetClassifier<
  K extends ListAttributeKey<Classifiers>,
  V extends Classifiers[K][number],
  S extends keyof V,
  Q extends keyof V,
>(classifierName: K, searchByAttribute: S, returnAttribute: Q) {
  const getClassifierObject = useGetClassifierObject(classifierName, searchByAttribute)
  return useCallback(
    (key: V[S] | number): V[Q] | undefined => getClassifierObject(key)?.[returnAttribute],
    [getClassifierObject, returnAttribute],
  )
}

export function useIsAdmin() {
  const [role] = useActiveRole()
  return role.data?.roleId === RoleId.Admin
}

export function useFactoryProfileUpdateForRole(facilityId: number) {
  const isAdmin = useIsAdmin()
  const updateQuery = useFactoryProfileUpdate(facilityId)
  const adminUpdateQuery = useAdminFactoryProfileUpdate(facilityId)
  return useMemo(() => (isAdmin ? adminUpdateQuery : updateQuery), [isAdmin, updateQuery, adminUpdateQuery])
}

export const useExternalShipmentOptions = (facilityId: number) => {
  const handlerQuery = useFactoryProfileHandlers(facilityId, { useErrorBoundary: false, enabled: !!facilityId })
  const data = useWithStringIds(handlerQuery.data?.handlers?.filter((h) => !h.isArchived))
  return useMemo(() => ({ ...handlerQuery, data }), [handlerQuery, data])
}

export interface InventoryModificationGroup {
  inventoryId: string
  incomingModification?: InventoryModification
  inventory: InventoryModification['inventory']
  totalAmount: InventoryModification['amount']
  orderModification?: InventoryModification
}

export function useModificationsByInventory(modifications: InventoryModification[], orderItems?: {}[]) {
  const orderModificationsByInventory = useOrderModificationsByInventory(orderItems)
  return useMemo(
    () =>
      Object.entries(groupBy(modifications, 'inventoryId')).map(([inventoryId, modifications]) => ({
        inventoryId,
        // inventory (lot) should have a single modification per order item, pick the first
        incomingModification: modifications.find((m) => m?.outputTransactionItem),
        // all are same, regardless whether an incoming modification exists
        inventory: modifications?.[0]?.inventory,
        totalAmount: sumBy(modifications, 'amount'),
        orderModification: orderModificationsByInventory[parseInt(inventoryId)],
      })),
    [modifications, orderModificationsByInventory],
  )
}

function useOrderModificationsByInventory(orderItems) {
  // shipment should have a single modification per order item, pick the first
  return useMemo(
    () => keyBy(orderItems?.map((i) => i.inputInventoryModifications?.[0]).filter((m) => !!m), 'inventoryId'),
    [orderItems],
  )
}

export function useLocationStateExpandedRows(key = 'expandedRows') {
  const navigate = useNavigate()
  const location = useLocation()

  return useMemo(() => {
    const { state, ...rest } = location
    const expandedRows = state?.[key] ?? {}
    return [
      expandedRows,
      function setState(id: number | string, value?: boolean) {
        navigate(rest, {
          replace: true,
          state: { ...state, [key]: { ...expandedRows, [id]: value ?? !expandedRows[id] } },
        })
      },
    ] as const
  }, [navigate, location, key])
}

export function useCopyDefaultValues(
  condition: boolean,
  values: { [k: string]: any },
  fromKeys: string[],
  toKeys: string[],
) {
  const { setFieldValue, initialValues } = useFormikContext()
  useEffect(() => {
    if (
      condition &&
      toKeys.every((k) => !getIn(initialValues, k)) &&
      toKeys.some((k, i) => getIn(values, k) !== getIn(values, fromKeys[i]))
    ) {
      toKeys.forEach((k, i) => {
        setFieldValue(k, getIn(values, fromKeys[i]))
      })
    }
  }, [values, setFieldValue, condition, fromKeys, toKeys, initialValues])
}

export function handlePrint() {
  window.print()
}

export function useLanguageOptions() {
  const { t: tx, i18n } = useTranslation()

  // maintaining state separately hides the moment after language switch
  //  but before new language has been applied
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.resolvedLanguage)

  const selectLanguage = useCallback(
    (code: string) => {
      i18n.changeLanguage(code)
      setSelectedLanguage(code)
    },
    [i18n, setSelectedLanguage],
  )

  return useMemo(
    () =>
      [
        selectedLanguage,
        (e) => selectLanguage(e.target.value),
        Object.keys(i18n.services.resourceStore.data).map((code) => ({ id: code, name: tx(code, { ns: 'locale' }) })),
      ] as const,
    [tx, i18n, selectedLanguage, selectLanguage],
  )
}

export function useIsReferenceChanged(...d: any[]) {
  const x = useRef<any[] | undefined>()

  const wasChanged = d.map((_, i) => x.current && d[i] !== x.current[i])
  x.current = d
  return wasChanged
}

export function useQueryParams<T extends object = {}>() {
  const { search } = useLocation()

  return useMemo(() => Object.fromEntries(new URLSearchParams(search)) as { [k: string]: string } & T, [search])
}

export function getQueryString(queryParams: { [k: string]: string | undefined }) {
  return new URLSearchParams(omitBy(queryParams, (p) => p === undefined) as { [k: string]: string }).toString()
}

/*  Used to keep a child filter value in sync with a parent filter value in react table components */
export function useInheritedFilter(parentFilters, parentFilterId, childFilters, childFilterId, setChildFilters) {
  const childFilterRef = useRef(childFilters)
  useEffect(() => {
    const parentValue = isFilterEnabled(parentFilters, parentFilterId)
    const childValue = isFilterEnabled(childFilterRef.current, childFilterId)

    if (parentValue !== childValue) {
      childFilterRef.current = childFilterRef.current.map((f) =>
        f.id === childFilterId ? { ...f, value: parentValue } : f,
      )
      setChildFilters(childFilterRef.current)
    }
  }, [parentFilters, parentFilterId, setChildFilters, childFilterId])
}

function isFilterEnabled(filters, id) {
  return find(filters, { id })?.value
}

export const useRecycledProductOutputCategoryOptions = (isProfile = false) => {
  const recycledProductOutputCategory = useClassifierOptionList('recycledProductOutputCategory')

  return useMemo(
    () =>
      recycledProductOutputCategory
        .filter((c) => !isProfile || !c.isHiddenInProfile)
        .map((r) => ({
          ...r,
          name: `${r.name} - ${r.asrCode}`,
        })),
    [isProfile, recycledProductOutputCategory],
  )
}

export const useRecycledProductOutputDetailOptions = (categoryId: number) => {
  const getRecycledProductOutputDetails = useGetClassifierSubOptions(
    'recycledProductOutputCategory',
    'id',
    'name',
    'recycledProductOutputDetails',
    'id',
    'name',
  )

  const recycledProductOutputDetails = getRecycledProductOutputDetails(categoryId.toString())

  return useMemo(
    () =>
      recycledProductOutputDetails
        ?.map((r) => ({
          ...r,
          name: r.asrCode ? `${r.name} - ${r.asrCode}` : r.name,
        }))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [recycledProductOutputDetails],
  )
}

// Temporary fix to not display a profile filled percentage over 100%
export const getCappedValue = (value) => {
  return Math.min(value, 100)
}

export const useInventoryBrandPartitions = (facilityId: number) => {
  const inventoryBrandPartitionQuery = useRemainingPartitionsForFacility(facilityId)
  return useMemo(() => inventoryBrandPartitionQuery.data, [inventoryBrandPartitionQuery.data])
}

export const useIncomingShipmentBrandPartitions = (facilityId: number) => {
  const query = useIncomingShipmentPartitionModifications(facilityId)
  return useMemo(() => query.data, [query.data])
}

export const useVolumesForIncomingShipment = (facilityId: number, brandFilter?: BrandInfo) => {
  const query = useIncomingShipmentVolumes(facilityId, brandFilter)
  return useMemo(() => query.data, [query.data])
}

export const useShipmentWithVolumes = (shipments, volumes) => {
  return useMemo(() => {
    return shipments.map((shipment) => {
      const volume = volumes?.find((v) => v.categoryTransactionId === shipment.id)
      return {
        ...shipment,
        volume,
      }
    })
  }, [shipments, volumes])
}

export enum BrandSummaryVolumeType {
  RemainingQuantity,
  Quantity,
}
export const useCalculateBrandSummaryVolume = (
  brandPartitions: ModificationPartitionAggregate[] | undefined,
  selectedBrand: ModificationPartitionAggregate | undefined,
  unknownBrandId: string | undefined,
  calculationType: BrandSummaryVolumeType,
) => {
  const unknownBrand = brandPartitions?.find(
    (b: ModificationPartitionAggregate) => b.brandId === Number(unknownBrandId),
  )
  const quantityField = calculationType === BrandSummaryVolumeType.Quantity ? 'quantityGm' : 'remainingQuantityGm'

  const totalStock = sumBy(brandPartitions, quantityField) / 1000
  const unbranded = (unknownBrand?.[quantityField] ?? 0) / 1000
  const branded = totalStock - unbranded
  const selected = (selectedBrand?.[quantityField] ?? 0) / 1000
  const other = branded - selected

  return {
    totalStock: formatVolumeNumber(totalStock),
    unbranded: formatVolumeNumber(unbranded),
    branded: formatVolumeNumber(branded),
    selected: formatVolumeNumber(selected),
    other: formatVolumeNumber(other),
  }
}

interface RecycledProductPartnerOption {
  externalId: number | undefined
  internalId: number | undefined
  label: string
  isExternalPartner: boolean
  countryId: string
}

export function useRecycledProductPartnerOptions(facilityId: number, isEditable: boolean) {
  const { t } = useTranslation()
  const partnerQuery = useRecyclerProfilePartners(facilityId)

  return useMemo(() => {
    const externalPartnerOptions =
      partnerQuery.data?.externalPartners
        ?.filter((h) => !h.isArchived)
        ?.map(({ id, name, contact, countryId }) => ({
          externalId: id,
          internalId: undefined,
          label: t('{{facilityName}} ({{contact}}) (not on platform)', { facilityName: name, contact }),
          countryId: countryId,
        })) ?? []
    const internalPartnerOptions = partnerQuery.data?.internalPartners?.map(
      ({ id, name, organization, countryId }) => ({
        externalId: undefined,
        internalId: id,
        label: `${organization.name}/${name}`,
        countryId: countryId,
      }),
    )

    const combinedOptions = [
      ...externalPartnerOptions,
      ...(internalPartnerOptions ?? []),
    ] as RecycledProductPartnerOption[]

    return { ...partnerQuery, data: combinedOptions } as UseQueryResult<RecycledProductPartnerOption[], ErrorResponse>
  }, [partnerQuery, t])
}

import {
  CategoryTransaction,
  Classifiers,
  FacilityWithHandlerDetails,
  FacilityWithOrganization,
  ManufacturerStockResponse,
  PurchaseOrder,
  PurchaseOrderItemDestinationTypeId,
  PurchaseOrderStatusId,
  PurchaseOrderSummary,
  RoleId,
} from '.'
import { Query, UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query'

import { cleanQuery } from 'src/api/utils'
import { useClassifierOptions } from 'src/hooks'
import { downloadUri } from 'src/utils'

import {
  MyQueryKey,
  defaultFetch,
  getQueryParamsFromObject,
  parseResponse,
  readDataUri,
  useFetchDelete,
  useFetchUpdate,
  useTokenQuery,
} from './query'
import { ErrorResponse } from './request'

export interface Pagination {
  skip: number
  limit: number
  itemCount: number
}

export interface PaginationOffset {
  offset: number
  limit?: number
}

export interface User {
  id: string
  emailAddress: string
  fullName: string
  phoneNumber: string
  isTcAccepted: boolean
  roles: any[]
}
interface UsersResponse {
  users: User[]
  pagination: PaginationOffset
}

interface UserResponse {
  user: User
}

export const useUsers = (limit: number, offset: number, query?: string) =>
  useTokenQuery<UsersResponse, ErrorResponse>([
    'v1/admin/users/',
    getQueryParamsFromObject({ limit, offset, query: cleanQuery(query) }),
  ])

export const useUser = (id: string) =>
  useTokenQuery<UserResponse, ErrorResponse>([`v1/admin/users/${id}/`], undefined, { enabled: !!id })

export interface Organization {
  id: number
  name: string
  isResearched: boolean
}

interface OrganizationResponse {
  organization: Organization
}

interface OrganizationsResponse {
  organizations: Organization[]
  pagination: PaginationOffset
}

export const useOrganizations = (offset: number, limit: number, query?: string) =>
  useTokenQuery<OrganizationsResponse, ErrorResponse>([
    'v1/admin/organizations/',
    getQueryParamsFromObject({ offset, limit, query }),
  ])
export const useOrganization = (id?: string) =>
  useTokenQuery<OrganizationResponse, ErrorResponse>([`v1/admin/organizations/${id}`], undefined, { enabled: !!id })

interface OrganizationCreate {
  name: string
}

export const useOrganizationCreate = () => {
  const queryClient = useQueryClient()
  const post = useFetchUpdate<OrganizationResponse, OrganizationCreate>('POST')
  return useMutation((data: OrganizationCreate) => post(`v1/admin/organizations/`, data), {
    onSuccess: () => queryClient.invalidateQueries(['v1/admin/organizations/']),
  })
}

export const useOrganizationUpdate = (id: string) => {
  const queryClient = useQueryClient()
  const patch = useFetchUpdate('PATCH')
  return useMutation((data: any) => patch(`v1/admin/organizations/${id}/`, data), {
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries(['v1/admin/facilities/']),
        queryClient.invalidateQueries(['v1/admin/manufacturers/']),
        queryClient.invalidateQueries(['v1/admin/handlers/']),
        queryClient.invalidateQueries(['v1/admin/recyclers/']),
      ]),
  })
}

export type ShipmentStatus = 'DRAFT' | 'SENT_OUT' | 'IN_TRANSIT' | 'ARRIVED' | 'CONFIRMED'
export const ALL_SHIPMENT_STATUSES: ShipmentStatus[] = ['DRAFT', 'SENT_OUT', 'IN_TRANSIT', 'ARRIVED', 'CONFIRMED']

export interface ShipmentsResponse {
  categoryTransactions: any[]
  pagination: Pagination
}

export const useShipments = (limit: number, skip: number, query?: string, statuses?: ShipmentStatus[]) =>
  useTokenQuery<ShipmentsResponse, ErrorResponse>([
    'v1/admin/category-transactions/',
    getQueryParamsFromObject({ limit, skip, query, s: statuses }),
  ])

interface CategoryTransactionComment {
  comment?: string
  commentUpdatedAt?: Date
  commentUpdatedByEmail?: string
}

interface DisputedShipmentsResponse {
  categoryTransactions: CategoryTransaction[]
  pagination: PaginationOffset
}

export const useDisputedShipments = (limit: number, offset: number) =>
  useTokenQuery<DisputedShipmentsResponse, ErrorResponse>([
    'v1/admin/category-transactions/disputed/',
    { offset, limit },
  ])

export function useUpdateCategoryTransactionComment(categoryTransactionId: number) {
  const queryClient = useQueryClient()
  const patch = useFetchUpdate<CategoryTransactionComment, { comment: string }>('PATCH')
  return useMutation<CategoryTransactionComment, ErrorResponse, { comment: string }>(
    async ({ comment }) =>
      await patch(`v1/admin/category-transactions/comment`, { ...{ categoryTransactionId }, comment }),
    { onSuccess: () => queryClient.invalidateQueries(['v1/admin/category-transactions/disputed/']) },
  )
}

export interface ProfileCopyParams {
  facilityId: number | string
  surveyId: string
}

export const useCopyProfileFromSurvey = () => {
  const queryClient = useQueryClient()
  const post = useFetchUpdate<{ facilityId: number }, ProfileCopyParams>('POST')
  return useMutation<{ facilityId: number }, ErrorResponse, ProfileCopyParams>(
    (data) => post(`v1/admin/manufacturers/profile-from-survey`, data),
    {
      onSuccess: ({ facilityId }) =>
        Promise.all([
          queryClient.invalidateQueries(['v1/current-user/']),
          queryClient.invalidateQueries([`v1/facilities/${facilityId}/factory-profile/`]),
        ]),
    },
  )
}

export interface FacilityBasicDetails {
  id: number
  name: string
  typeId: string
  isResearched: boolean
}

interface FacilitiesResponse {
  facilities: FacilityBasicDetails[]
}

export function useFacilities<T = FacilitiesResponse>(
  organizationId?: string,
  options: UseQueryOptions<FacilitiesResponse, ErrorResponse, T, MyQueryKey> = {},
) {
  return useTokenQuery<FacilitiesResponse, ErrorResponse, T>(
    ['v1/admin/facilities/', getQueryParamsFromObject({ org: organizationId })],
    undefined,
    options,
  )
}

interface FacilityCreateAdmin {
  name: string
  organization_id: number
}
interface FacilityResponse {
  facility: FacilityBasicDetails
}

export const useFacilityCreate = () => {
  const queryClient = useQueryClient()
  const post = useFetchUpdate<FacilityResponse, FacilityCreateAdmin>('POST')
  return useMutation((data: FacilityCreateAdmin) => post(`v1/admin/facilities/`, data), {
    onSuccess: () => queryClient.invalidateQueries(['v1/admin/facilities/']),
  })
}

interface ManufacturersResponse {
  manufacturers: any[]
  numberOfFacilities: number
}

export const useManufacturers = () =>
  useTokenQuery<ManufacturersResponse, ErrorResponse>(['v1/admin/manufacturers/', { skip: 0, limit: 800 }])

export const usePaginatedManufacturers = (
  pageIndex: number,
  sort: { id: string; desc: boolean }[] = [],
  pageSize = 50,
  query: string,
) =>
  useTokenQuery<ManufacturersResponse, ErrorResponse, { manufacturers: any[]; pageCount: number }>(
    [
      'v1/admin/manufacturers/',
      {
        limit: pageSize,
        skip: pageSize * pageIndex,
        query: query.replaceAll(/\s+/g, ' ').trim(),
        cacheTime: 0,
        'sort-by': sort.map((s) => `${s.desc ? '-' : '+'}${s.id}`),
      },
    ],
    undefined,
    {
      select: ({ manufacturers, numberOfFacilities }) => ({
        manufacturers,
        pageCount: Math.ceil(numberOfFacilities / pageSize),
      }),
    },
  )

interface RecyclersResponse {
  recyclers: any[]
  pagination: Pagination
}

export function useRecyclers<T = RecyclersResponse>(
  limit: number,
  skip: number,
  query?: string,
  options: UseQueryOptions<RecyclersResponse, ErrorResponse, T, MyQueryKey> = {},
) {
  return useTokenQuery<RecyclersResponse, ErrorResponse, T>(
    ['v1/admin/recyclers/', getQueryParamsFromObject({ limit, skip, query: cleanQuery(query) })],
    undefined,
    options,
  )
}

interface HandlerResponse {
  handlers: FacilityWithHandlerDetails[]
}

export const useHandlers = () =>
  useTokenQuery<HandlerResponse, ErrorResponse>(['v1/admin/handlers/', { skip: 0, limit: 600 }])

interface BrandResponse {
  brands: any[]
}

export const useAdminBrands = () =>
  useTokenQuery<BrandResponse, ErrorResponse>(['v1/admin/brands/', { skip: 0, limit: 600 }])

export const useUserCreate = () => {
  const queryClient = useQueryClient()
  const post = useFetchUpdate('POST')
  return useMutation((data: any) => post(`v1/admin/users/`, data), {
    onSuccess: () => queryClient.invalidateQueries(['v1/admin/users/']),
  })
}

export const useUserSendPasswordResetEmail = (id: string) => {
  const queryClient = useQueryClient()
  const patch = useFetchUpdate('PATCH')
  return useMutation(() => patch(`v1/admin/users/password-reset/${id}`), {
    onSuccess: () => queryClient.invalidateQueries([`v1/admin/users/${id}/`]),
  })
}

export const useFacilityUpdate = (id: string) => {
  const queryClient = useQueryClient()
  const patch = useFetchUpdate<{ facility: any }>('PATCH')
  return useMutation<any, ErrorResponse, any>(
    async (data) => (await patch(`v1/admin/facilities/${id}/`, data)).facility,
    {
      onSuccess: () =>
        Promise.all([
          queryClient.invalidateQueries(['v1/admin/facilities/']),
          queryClient.invalidateQueries(['v1/admin/manufacturers/']),
          queryClient.invalidateQueries(['v1/admin/handlers/']),
          queryClient.invalidateQueries(['v1/admin/recyclers/']),
        ]),
    },
  )
}

export const useBrandMembershipUpdate = (id: string) => {
  const queryClient = useQueryClient()
  const patch = useFetchUpdate<{ brand: any }>('PATCH')
  return useMutation<any, ErrorResponse, any>(async (data) => (await patch(`v1/admin/brands/${id}/`, data)).brand, {
    onSuccess: () => Promise.all([queryClient.invalidateQueries(['v1/admin/brands/'])]),
  })
}

export const useUserUpdate = (id: string) => {
  const queryClient = useQueryClient()
  const patch = useFetchUpdate('PATCH')
  return useMutation((data: any) => patch(`v1/admin/users/${id}/`, data), {
    onSuccess: async (response: any) => queryClient.setQueryData([`v1/admin/users/${id}/`], response),
  })
}

export const useUserAssignRole = (id: string) => {
  const queryClient = useQueryClient()
  const patch = useFetchUpdate('PATCH')
  return useMutation((data: any) => patch(`v1/admin/users/${id}/assign-role/`, data), {
    onSuccess: () => queryClient.invalidateQueries([`v1/admin/users/${id}/`]),
  })
}

export const useShipmentReceiverUpdate = (facilityId: number, shipmentId: number) => {
  const queryClient = useQueryClient()
  const patch = useFetchUpdate('PATCH')
  return useMutation((data: any) => patch(`v1/admin/category-transactions/${shipmentId}/`, data), {
    onSuccess: async (response: any) => {
      queryClient.setQueryData([`v1/category-transactions/${shipmentId}/`], response)
      await Promise.all([
        queryClient.invalidateQueries([`v1/facilities/${facilityId}/category-transactions/`]),
        queryClient.invalidateQueries([`v1/current-organization/category-transactions/`]),
      ])
    },
  })
}

export const useAdminShipmentStatusUpdate = (facilityId: number, shipmentId: number) => {
  const queryClient = useQueryClient()
  const put = useFetchUpdate('PUT')
  return useMutation((data: any) => put(`v1/admin/category-transactions/${shipmentId}/status/`, data), {
    onSuccess: async (response: any) => {
      queryClient.setQueryData([`v1/category-transactions/${shipmentId}/`], response)
      await Promise.all([
        queryClient.invalidateQueries(['v1/admin/category-transactions/', { skip: 0, limit: 300 }]),
        queryClient.invalidateQueries([`v1/facilities/${facilityId}/category-transactions/`]),
        queryClient.invalidateQueries([`v1/current-organization/category-transactions/`]),
      ])
    },
  })
}

export const useUpdateInventoryModificationDate = (facilityId: number, categoryInventoryId: number) => {
  const queryClient = useQueryClient()
  const patch = useFetchUpdate('PATCH')
  return useMutation(
    (data: any) => patch(`v1/admin/category-inventories/${categoryInventoryId}/modifications/${data.id}/`, data),
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries([`v1/category-inventories/${categoryInventoryId}/modifications/`]),
          queryClient.invalidateQueries({
            predicate: (query: Query<unknown, unknown, unknown, any>) =>
              query.queryKey[0] === `v1/facilities/${facilityId}/inventory-modifications/` &&
              query.queryKey?.[1]?.['inventory-ids']?.includes(categoryInventoryId),
          }),
        ])
      },
    },
  )
}

export const useFactoryProfileUpdate = (facilityId: number) => {
  const queryClient = useQueryClient()
  const patch = useFetchUpdate('PATCH')
  return useMutation((data: any) => patch(`v1/admin/facilities/${facilityId}/factory-profile/`, data), {
    onSuccess: async (response) => {
      queryClient.setQueryData([`v1/facilities/${facilityId}/factory-profile/`], response)
      await Promise.all([
        queryClient.invalidateQueries([`v1/facilities/`]),
        queryClient.invalidateQueries([`v1/facilities/${facilityId}/factory-profile/handlers/`]),
        queryClient.invalidateQueries([`v1/facilities/${facilityId}/categories-with-stock/`]),
      ])
    },
  })
}

export const useManufacturerStock = () =>
  useTokenQuery<ManufacturerStockResponse, ErrorResponse>(['v1/admin/inventory/manufacturer/', { skip: 0, limit: 600 }])

export interface CategoryStockResponse {
  categoryGroups: {
    colorGroupId?: number

    productionMaterialTypeId?: number
    productionWasteTypeId?: number
    productionWasteSubtypeId?: number

    compositions: { compositionId: number; percentage: number }[]

    stockAmountKg?: number
  }
}

export const useCategoryGroups = (countryId: string) =>
  useTokenQuery<CategoryStockResponse, ErrorResponse>([
    'v1/admin/inventory/category-groups/',
    { skip: 0, limit: 300, 'country-id': countryId },
  ])

export interface FacilityCategoryInventoryGroup {
  facility: FacilityWithOrganization
  stockAmountKg: number
}

interface InventoryByFacilityBody {
  inventoryIds: number[]
}
interface InventoryByFacilityResponse {
  inventories: FacilityCategoryInventoryGroup[]
}

export const useInventories = (inventoryIds: number[]) => {
  // The endpoint expect a POST request, but does not change anything on the backend,
  // so treat it like a query.
  const post = useFetchUpdate<InventoryByFacilityResponse, InventoryByFacilityBody>('POST')
  return useQuery<InventoryByFacilityResponse, ErrorResponse>(
    ['v1/admin/inventory/by-facility/', { inventoryIds }],
    () => post('v1/admin/inventory/by-facility/', { inventoryIds }),
    { enabled: !!inventoryIds },
  )
}

export const useInventoryExport = (selectedCountry?: string, selectedFacilityId?: number) =>
  useTokenQuery<unknown, ErrorResponse>(
    [
      'v1/admin/facilities/inventory-export/',
      {
        ...(selectedCountry ? { 'country-id': selectedCountry } : {}),
        ...(selectedFacilityId ? { 'facility-id': selectedFacilityId } : {}),
      },
    ],
    async (context) => downloadResponseAttachment(await defaultFetch(...context.queryKey)),
    { enabled: false, cacheTime: 0 },
  )

export const useProfileExport = () =>
  useTokenQuery<unknown, ErrorResponse>(
    ['v1/admin/facilities/profile-export/'],
    async (context) => downloadResponseAttachment(await defaultFetch(context.queryKey[0])),
    { enabled: false, cacheTime: 0 },
  )

export const useRecyclerProfileExport = () =>
  useTokenQuery<unknown, ErrorResponse>(
    ['v1/admin/facilities/recycler-export/'],
    async (context) => downloadResponseAttachment(await defaultFetch(context.queryKey[0])),
    { enabled: false, cacheTime: 0 },
  )

export const useHandlerProfileExport = () =>
  useTokenQuery<unknown, ErrorResponse>(
    ['v1/admin/facilities/handler-export/'],
    async (context) => downloadResponseAttachment(await defaultFetch(context.queryKey[0])),
    { enabled: false, cacheTime: 0 },
  )

export const useShipmentExport = (selectedCountry?: string) =>
  useTokenQuery<unknown, ErrorResponse>(
    ['v1/admin/category-transactions/shipment-export/', selectedCountry ? { 'country-id': selectedCountry } : {}],
    async (context) => downloadResponseAttachment(await defaultFetch(...context.queryKey)),
    { enabled: false, cacheTime: 0 },
  )

export const useProfileSurveyExport = () =>
  useTokenQuery<unknown, ErrorResponse>(
    ['v1/admin/facilities/profile-survey-export/'],
    async (context) => downloadResponseAttachment(await defaultFetch(context.queryKey[0])),
    { enabled: false, cacheTime: 0 },
  )

export const usePurchaseOrderExport = (purchaseOrderId: number) =>
  useTokenQuery<unknown, ErrorResponse>(
    [`v1/admin/purchase-orders/${purchaseOrderId}/export`],
    async (context) => downloadResponseAttachment(await defaultFetch(...context.queryKey)),
    { enabled: false, cacheTime: 0 },
  )

async function downloadResponseAttachment(response) {
  const fileName = response.headers.get('content-disposition')?.match(/filename="([^"]+)"/)?.[1] ?? 'export.xlsx'
  const blob = await parseResponse(response, 'blob')
  const dataUri = await readDataUri(blob)
  downloadUri(dataUri, fileName)
}

interface IncomingFacilityPreferredPartner {
  incomingPreferredPartnerFacilityIds: number[]
}

export const useUpdateFacilityIncomingPreferredPartners = (facilityId?: number) => {
  const queryClient = useQueryClient()
  const patch = useFetchUpdate<IncomingFacilityPreferredPartner, IncomingFacilityPreferredPartner>('PATCH')
  return useMutation<IncomingFacilityPreferredPartner, ErrorResponse, IncomingFacilityPreferredPartner>(
    (data) => patch(`v1/admin/facilities/${facilityId}/incoming-preferred-partner-facilities/`, data),
    { onSuccess: () => queryClient.invalidateQueries(['v1/admin/recyclers/']) },
  )
}

interface NewPurchaseOrderInput {
  categoryIds: number[]
}

interface PurchaseOrdersResponse {
  purchaseOrders: PurchaseOrderSummary[]
  pagination: Pagination
}

export function usePurchaseOrders(limit: number, skip: number, query?: string) {
  return useTokenQuery<PurchaseOrdersResponse, ErrorResponse>(
    ['v1/admin/purchase-orders/', getQueryParamsFromObject({ limit, skip, query })],
    undefined,
  )
}

export const usePurchaseOrder = (id: number) =>
  useTokenQuery<{ purchaseOrder: PurchaseOrder }, ErrorResponse, PurchaseOrder>(
    [`v1/admin/purchase-orders/${id}/`],
    undefined,
    { select: (o) => o.purchaseOrder },
  )

export const usePurchaseOrderCreate = (facilityId: number) => {
  const queryClient = useQueryClient()
  const post = useFetchUpdate<{ purchaseOrder: PurchaseOrder }, NewPurchaseOrderInput>('POST')
  return useMutation<PurchaseOrder, ErrorResponse, NewPurchaseOrderInput>(
    async (values) => (await post(`v1/admin/facilities/${facilityId}/purchase-orders/`, values)).purchaseOrder,
    {
      onSuccess: (response) => {
        queryClient.setQueryData([`v1/admin/purchase-orders/${response.id}/`], { purchaseOrder: response })
        queryClient.invalidateQueries(['v1/admin/purchase-orders/'])
      },
    },
  )
}

export const usePurchaseOrderUpdate = (id: number) => {
  const queryClient = useQueryClient()
  const patch = useFetchUpdate<{ purchaseOrder: PurchaseOrder }, PurchaseOrder>('PATCH')
  return useMutation<PurchaseOrder, ErrorResponse, PurchaseOrder>(
    async (values) => (await patch(`v1/admin/purchase-orders/${id}/`, values)).purchaseOrder,
    {
      onSuccess: (response) => {
        queryClient.setQueryData([`v1/admin/purchase-orders/${id}/`], { purchaseOrder: response })
        queryClient.invalidateQueries(['v1/admin/purchase-orders/'])
      },
    },
  )
}

export const usePurchaseOrderStatusUpdate = (id: number) => {
  const queryClient = useQueryClient()
  const patch = useFetchUpdate<{ purchaseOrder: PurchaseOrder }, { statusId: PurchaseOrderStatusId }>('PATCH')
  return useMutation<PurchaseOrder, ErrorResponse, { statusId: PurchaseOrderStatusId }>(
    async ({ statusId }) => (await patch(`v1/admin/purchase-orders/${id}/status/`, { statusId })).purchaseOrder,
    {
      onSuccess: (response) =>
        Promise.all([
          queryClient.setQueryData([`v1/admin/purchase-orders/${id}/`], { purchaseOrder: response }),
          queryClient.invalidateQueries(['v1/admin/purchase-orders/']),
          ...response.items.map((i) =>
            Promise.all([
              queryClient.invalidateQueries(`v1/categories/${i.categoryId}/purchase-order-items/`),
              queryClient.invalidateQueries(`v1/categories/${i.categoryId}/stock/`),
            ]),
          ),
        ]),
    },
  )
}

function getPurchaseOrderFacilityOptions(data: FacilitiesResponse) {
  return data.facilities
    ?.map((f) => ({ id: f.id.toString(), label: f.name, isOther: false }))
    .concat({ id: '', label: 'Other', isOther: true })
}

export function usePurchaseOrderFacilityOptions() {
  return useFacilities(undefined, { select: getPurchaseOrderFacilityOptions })
}

function getPurchaseOrderRecyclerOptions(
  data: RecyclersResponse,
  types: Classifiers['purchaseOrderItemDestinationTypes'],
) {
  return data.recyclers
    .map((r) => ({ id: r.id, label: r.name, typeId: PurchaseOrderItemDestinationTypeId.FACILITY }))
    .concat(
      types
        .filter((t) => t.id !== PurchaseOrderItemDestinationTypeId.FACILITY)
        .map((t) => ({ id: '', typeId: t.id, label: t.name })),
    )
}

export function usePurchaseOrderRecyclerOptions(disabled?: boolean) {
  const destinationTypes = useClassifierOptions('purchaseOrderItemDestinationTypes')
  return useRecyclers(300, 0, undefined, {
    select: (data) => getPurchaseOrderRecyclerOptions(data, (destinationTypes.data as any) ?? []),
    enabled: !disabled && destinationTypes.isFetched,
  })
}

export function useSignUpLinks() {
  return useTokenQuery<{ codes: [{ id: string; roleId: RoleId; brandId: string; projectId: string }] }, ErrorResponse>([
    'v1/admin/sign-ups/',
  ])
}

export const usePurchaseOrderDelete = (id: number) => {
  const queryClient = useQueryClient()
  const doDelete = useFetchDelete()

  return useMutation<void, ErrorResponse>(async () => doDelete(`v1/admin/purchase-orders/${id}/`), {
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries(['v1/admin/purchase-orders/']),
        queryClient.removeQueries(`v1/admin/purchase-orders/${id}/`),
      ]),
  })
}

export interface CategoryTransactionOverviewResponse {
  groupedItems: CategoryTransactionOverviewItem[]
}

interface CategoryTransactionOverviewItemComment {
  comment?: string
  commentUpdatedAt?: Date
  commentUpdatedByEmail?: string
}

export interface CategoryTransactionOverviewItem extends CategoryTransactionOverviewItemComment {
  colorGroupId: number
  productionMaterialTypeId: number
  unsortedStockQuantityGm: number
  unsortedBrandedStockQuantityGm: number
  sortedStockQuantityGm: number
  sortedBrandedStockQuantityGm: number
  compositionGroupId?: number
  compositionGroupName?: string
}

export function useTransactionOverview({ brandId, facilityId }: { brandId?: number; facilityId?: number }) {
  return useTokenQuery<CategoryTransactionOverviewResponse, ErrorResponse, CategoryTransactionOverviewItem[]>(
    [
      'v1/admin/category-transactions/overview/',
      {
        ...(facilityId ? { 'facility-id': facilityId } : {}),
        ...(brandId ? { 'brand-id': brandId } : {}),
      },
    ],
    undefined,
    { select: (r) => r.groupedItems },
  )
}

export function useUpdateTransactionOverview(item: CategoryTransactionOverviewItem) {
  const queryClient = useQueryClient()
  const patch = useFetchUpdate<CategoryTransactionOverviewItemComment, { comment: string }>('PATCH')
  return useMutation<CategoryTransactionOverviewItemComment, ErrorResponse, { comment: string }>(
    async ({ comment }) => await patch(`v1/admin/category-transactions/overview`, { ...item, comment }),
    {
      onSuccess: function (newItem) {
        queryClient.setQueriesData<CategoryTransactionOverviewResponse>(
          ['v1/admin/category-transactions/overview/'],
          (response) => ({
            groupedItems:
              response?.groupedItems.map((responseItem) =>
                isMatchingOverviewItem(responseItem, item) ? { ...responseItem, ...newItem } : responseItem,
              ) ?? [],
          }),
        )
      },
    },
  )
}

function isMatchingOverviewItem(a: CategoryTransactionOverviewItem, b: CategoryTransactionOverviewItem) {
  return (
    a.colorGroupId === b.colorGroupId &&
    a.productionMaterialTypeId === b.productionMaterialTypeId &&
    a.unsortedStockQuantityGm === b.unsortedStockQuantityGm
  )
}
